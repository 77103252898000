import React from 'react';
import { CardList } from '_components';
import { Article } from '_types';
import './CardListTiles.scss';

type CardListTilesProps = {
  articles: Article[];
};

const CardListTiles: React.FC<CardListTilesProps> = (props) => {

  return (
    <div className="CardListTiles">
      <CardList articles={props.articles} />
    </div>
  );
};

export { CardListTiles };
