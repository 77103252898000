import React from 'react';
import { ArticleHeader, MetaTags, MoreReading, SiteFooter, BuyMeACoffee } from '_components';
import { Article } from '_types';
import { getDraftFeatures } from 'articles';
import './ArticleTemplate.scss';
import { PageSection } from './PageSection';

type ArticleTemplateProps = {
  article: Article;
  banner: string;
  caption?: string | JSX.Element;
};

const ArticleTemplate: React.FC<ArticleTemplateProps> = (props) => {

  const draftArticles = getDraftFeatures();
  const showPreviewBanner = draftArticles.includes(props.article);

  return (
    <div className="ArticleTemplate">

      <MetaTags
        title={`${props.article.title} | Fading Hours`}
        description={props.article.description}
        image={props.article.image}
      />

      {showPreviewBanner &&
        <div className="ArticleTemplate__previewBanner">
          <h4 className="ArticleTemplate__previewBannerText" title="This article is currently in draft status and is not yet live on the site. Please do not share the URL.">&nbsp;</h4>
        </div>
      }

      <ArticleHeader
        article={props.article}
        image={props.banner}
        caption={props.caption}
      />

      <div className="ArticleTemplate__content">
        {props.children}

        <PageSection maxWidth="narrow">
          <BuyMeACoffee />
        </PageSection>

      </div>

      <div className="ArticleTemplate__footer">
        <PageSection maxWidth="wide">
          <MoreReading title="More Features" count={6} />
        </PageSection>
      </div>

      <SiteFooter />

    </div>
  );
};

export { ArticleTemplate };
