import React from 'react';
import clsx from 'clsx';
import './PageSection.scss';

type PageSectionProps = {
  className?: string;
  /** Narrow (750px), Normal (1200px), Wide (1560px) */
  maxWidth?: 'narrow' | 'normal' | 'wide' | (string & {});
  style?: React.CSSProperties;
  gutter?: boolean;
  tint?: 'white';
};

const PageSection: React.FC<PageSectionProps> = (props) => {

  const innerStyles: React.CSSProperties = {};
  if (props.maxWidth === 'narrow') innerStyles.maxWidth = '750px';
  else if (props.maxWidth === 'normal') innerStyles.maxWidth = '1200px';
  else if (props.maxWidth === 'wide') innerStyles.maxWidth = '1560px';
  else if (props.maxWidth) innerStyles.maxWidth = props.maxWidth;

  const classes = clsx('PageSection', props.className, {
    'PageSection--gutter': props.gutter,
    'PageSection--tintWhite': props.tint === 'white',
  });


  return (
    <div className={classes} style={props.style}>
      <div className="PageSection__inner" style={innerStyles}>
        {props.children}
      </div>
    </div>
  );
};

PageSection.defaultProps = {
  gutter: true,
};

export { PageSection };
