import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { LazyImage, TimeAgo } from '_components';
import { Article } from '_types';
import './Card.scss';

type CardProps = {
  article: Article;
  className?: string;
};

const Card: React.FC<CardProps> = (props) => {

  const classes = clsx('Card', 'u-anchor', props.className);

  return (
    <Link className={classes} to={props.article.path}>
      <div className="Card__grid">
        <div className="Card__gridImage">
          <LazyImage className="Card__image" src={props.article.image} blurhash={props.article.blurhash} />
          <div className="Card__imageProtection"></div>
        </div>
        <div className="Card__gridText">
          <div className="Card__category">{props.article.category}</div>
          <h3 className="Card__title">{props.article.title}</h3>
          <p className="Card__description">{props.article.shortDescription}</p>
          <TimeAgo className="Card__timeAgo" date={props.article.date} />
        </div>
      </div>
    </Link>
  );
};

export { Card };
