import React from 'react';
import clsx from 'clsx';
import './ArrowAction.scss';
import { ReactComponent as IconArrowRight } from '_images/icons/arrowRight.svg';

type ArrowActionProps = {
  label: string;
  className?: string;
};

const ArrowAction: React.FC<ArrowActionProps> = (props) => {

  const classes = clsx('ArrowAction', 'u-anchor', props.className);

  return (
    <div className={classes}>
      <span className="ArrowAction__label">{props.label}</span>
      <IconArrowRight className="ArrowAction__arrow" />
    </div>
  );
};

export { ArrowAction };
