import React from 'react';
import { LazyImage } from '_components';
import './ArticleImage.scss';

type ArticleImageProps = {
  image: string;
  blurhash?: string;
  imagePaddingTop?: string;
  caption?: string | JSX.Element;
};

const ArticleImage: React.FC<ArticleImageProps> = (props) => {

  return (
    <div className="ArticleImage">
      <div className="ArticleImage__image">
        <LazyImage src={props.image} paddingTop={props.imagePaddingTop} blurhash={props.blurhash} />
      </div>
      {props.caption &&
        <small className="ArticleImage__caption">{props.caption}</small>
      }
    </div>
  );
};

export { ArticleImage };
