import React from 'react';
import { Link } from 'react-router-dom';
import { LazyImage, TimeAgo, ArrowAction, PageSection } from '_components';
import { Article } from '_types';
import './HeroTile.scss';
// import { ReactComponent as IconInstagram } from '_images/icons/FA-instagram.svg';
// import { ExternalLink } from './ExternalLink';

type HeroTileProps = {
  articles: [Article, Article];
};

const HeroTile: React.FC<HeroTileProps> = (props) => {

  return (
    <div className="HeroTile">

      <div className="HeroTile__back">
        <LazyImage
          className="HeroTile__backImage"
          src={props.articles[0].image}
          paddingTop="75%"
          blurhash={props.articles[0].blurhash}
        />
        <LazyImage
          className="HeroTile__backImage"
          src={props.articles[1].image}
          paddingTop="75%"
          blurhash={props.articles[1].blurhash}
        />
        <div className="HeroTile__backOverlay"></div>
      </div>

      <PageSection maxWidth="wide" className="HeroTile__tilesWrapper" gutter={false}>
        <div className="HeroTile__tiles">
          <Link className="HeroTile__tile u-anchor" to={props.articles[0].path}>
            <div className="HeroTile__imageWrapper">
              <LazyImage className="HeroTile__image" src={props.articles[0].image} blurhash={props.articles[0].blurhash} />
              <div className="HeroTile__imageProtection"></div>
            </div>
            <div className="HeroTile__text">
              <div className="HeroTile__category">{props.articles[0].category}</div>
              <h1 className="HeroTile__title">{props.articles[0].title}</h1>
              <h4 className="HeroTile__description">{props.articles[0].shortDescription}</h4>
              <TimeAgo className="HeroTile__timeAgo" date={props.articles[0].date} />
              <ArrowAction className="HeroTile__read" label="Read Feature" />
            </div>
          </Link>
          <Link className="HeroTile__tile u-anchor" to={props.articles[1].path}>
            <div className="HeroTile__imageWrapper">
              <LazyImage className="HeroTile__image" src={props.articles[1].image} blurhash={props.articles[1].blurhash} />
              <div className="HeroTile__imageProtection"></div>
            </div>
            <div className="HeroTile__text">
              <div className="HeroTile__category">{props.articles[1].category}</div>
              <h1 className="HeroTile__title">{props.articles[1].title}</h1>
              <h4 className="HeroTile__description">{props.articles[1].shortDescription}</h4>
              <TimeAgo className="HeroTile__timeAgo" date={props.articles[1].date} />
              <ArrowAction className="HeroTile__read" label="Read Feature" />
            </div>
          </Link>
        </div>
      </PageSection>

      {props.children}

    </div>
  );
};

export { HeroTile };
