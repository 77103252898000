import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Blurhash } from 'react-blurhash';
import { useInView } from 'react-intersection-observer';
import './LazyImage.scss';

type LazyImageProps = {
  src: string;
  blurhash?: string;
  color?: string;
  paddingTop?: string;
  className?: string;
}

const LazyImage: React.FC<LazyImageProps> = (props) => {

  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });

  const lazyImageStyles: React.CSSProperties = {};
  if (props.paddingTop) lazyImageStyles.paddingTop = props.paddingTop;
  if (props.color) lazyImageStyles.backgroundColor = props.color;

  const [imageSrc, setImageSrc] = useState<string>('');
  useEffect(() => {
    if (!inView) return;
    setImageSrc(props.src);
  }, [inView, props.src]);

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const handleImageLoad = (evt: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setImageLoaded(true);
  };

  const imageClasses = clsx('LazyImage__image', {
    'LazyImage__image--loaded': imageLoaded,
  });

  const lazyImageClasses = clsx('LazyImage', props.className);

  return (
    <div className={lazyImageClasses} style={lazyImageStyles}>
      {props.blurhash &&
        <Blurhash className="LazyImage__blurhash" hash={props.blurhash} width="100%" height="100%" />
      }
      <img ref={ref} className={imageClasses} src={imageSrc} onLoad={handleImageLoad} alt="" />
    </div>
  );
}

export { LazyImage };
