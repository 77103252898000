import React from 'react';
import { useInView } from 'react-intersection-observer';
import { LazyImage, ScrollDownNotice } from '_components';
import { Article } from '_types';
import './ArticleHeader.scss';

type ArticleHeaderProps = {
  article: Article;
  image: string;
  caption?: string | JSX.Element;
};

const ArticleHeader: React.FC<ArticleHeaderProps> = (props) => {

  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });

  const formatDate = (date: string): string => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('en-gb', { day: 'numeric', month: 'long', year: 'numeric' });
  };

  return (
    <div className="ArticleHeader">
      <div className="ArticleHeader__imageWrap">
        <LazyImage className="ArticleHeader__image" src={props.image} paddingTop="66.67%" blurhash={props.article.blurhash} />
        {props.caption &&
          <small className="ArticleImages__caption">{props.caption}</small>
        }
        <ScrollDownNotice className="ArticleHeader__imageScrollDownNotice" visible={inView} />
      </div>
      <h1 ref={ref} className="ArticleHeader__title">{props.article.title}</h1>
      <p className="ArticleHeader__date">{formatDate(props.article.date)}</p>
    </div>
  );
};

export { ArticleHeader };
