import React from 'react';
import { useRecoilState } from 'recoil';
import { ExternalLink } from '_components';
import { siteMenuVisibleState } from '_state';
import './MediaIcons.scss';
import { ReactComponent as IconTwitter } from '_images/icons/FA-twitter.svg';
import { ReactComponent as IconInstagram } from '_images/icons/FA-instagram.svg';
import { ReactComponent as IconBuyMeACoffee } from '_images/icons/bmc-logo.svg';

type MediaIconsProps = {};

// Note this is not called SocialMediaIcons because otherwise it gets blocked by adblockers
const MediaIcons: React.FC<MediaIconsProps> = (props) => {

  const [, setSiteMenuVisible] = useRecoilState(siteMenuVisibleState);

  const handleClick = () => {
    setSiteMenuVisible(false);
  };

  return (
    <div className="MediaIcons">
      <div className="MediaIcons__grid">
        <div>
          <ExternalLink
            className="MediaIcons__link u-anchor u-anchor--fadeHover"
            href="https://www.buymeacoffee.com/fadinghours"
            onClick={handleClick}>
            <IconBuyMeACoffee className="MediaIcons__icon" />
          </ExternalLink>
        </div>
        <div>
          <ExternalLink
            className="MediaIcons__link u-anchor u-anchor--fadeHover"
            href="http://www.instagram.com/fadinghours"
            onClick={handleClick}>
            <IconInstagram className="MediaIcons__icon" />
          </ExternalLink>
        </div>
        <div>
          <ExternalLink
            className="MediaIcons__link MediaIcons__link3 u-anchor u-anchor--fadeHover"
            href="https://twitter.com/fadinghoursmag"
            onClick={handleClick}>
            <IconTwitter className="MediaIcons__icon" />
          </ExternalLink>
        </div>
      </div>
    </div>
  );
};

export { MediaIcons };
