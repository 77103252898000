import React from 'react';
import { useRecoilState } from 'recoil';
import clsx from 'clsx';
import { siteMenuVisibleState } from '_state';
import './SiteMenuHamburger.scss';

type SiteMenuHamburgerProps = {};

const SiteMenuHamburger: React.FC<SiteMenuHamburgerProps> = (props) => {

  const [siteMenuVisible, setSiteMenuVisible] = useRecoilState(siteMenuVisibleState);

  const handleClick = () => {
    setSiteMenuVisible(state => !state);
  };

  const classes = clsx('SiteMenuHamburger', 'u-button', {
    'SiteMenuHamburger--active': siteMenuVisible,
  });

  return (
    <button className={classes} onClick={handleClick}>
      <div className="SiteMenuHamburger__icon"></div>
    </button>
  );
};

export { SiteMenuHamburger };
