import React from 'react';
import { LinksList } from '_components';
import './ArtistLinks.scss';

type ArtistLinksProps = {
  groups: {
    label: 'Behance' | 'Foundation' | 'Instagram' | 'Music' | 'Other' | 'Prints' | 'Twitch' | 'Twitter' | 'Web';
    items: {
      name: string;
      url: string | null;
    }[];
  }[];
};

const ArtistLinks: React.FC<ArtistLinksProps> = (props) => {

  return (
    <div className="ArtistLinks">
      <div className="ArtistLinks__grid">
        <div className="ArtistLinks__gridTitle">
          <h3 className="ArtistLinks__title">Artist Links</h3>
        </div>
        <div className="ArtistLinks__gridProducts">
          <LinksList groups={props.groups} />
        </div>
      </div>
    </div>
  );
};

export { ArtistLinks };
