import React from 'react';
import { Article } from '_types';

// Use https://blurha.sh to generate blurhash

// The asArticles function is used as an alternative to using the index signature below. The function ensures that autocomplete
// does not get broken.
//
// type Articles = {
//   [key: string]: Article;
// };
//
// Taken from: https://stackoverflow.com/questions/52146544/why-autocomplete-stop-working-in-an-object-with-type-in-typescript
// Also see: https://youtrack.jetbrains.com/issue/WEB-34642

function asArticles<T extends { [key: string]: Article }>(arg: T): T {
  return arg;
}

const ARTICLES = asArticles({
  // FEATURE_NAMESURNAME: {
  //   title: 'NAMESURNAME',
  //   description: 'Description.',
  //   shortDescription: 'Short Description',
  //   category: 'Feature',
  //   date: '2050-04-14T14:00:00.000Z',
  //   image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureNAMESURNAME.jpg`,
  //   blurhash: null,
  //   path: '/features/NAMESURNAME/',
  //   hidden: false,
  //   component: React.lazy(() => import('Features/NAMESURNAME/FeatureNAMESURNAMERoute')),
  // },
  // FEATURE_BRAYDEN_M: {
  //   title: 'Brayden M',
  //   description: 'Description.',
  //   shortDescription: 'Short Description',
  //   category: 'Feature',
  //   date: '2050-04-14T14:00:00.000Z',
  //   image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureBraydenM.jpg`,
  //   blurhash: null,
  //   path: '/features/brayden-m/',
  //   hidden: false,
  //   component: React.lazy(() => import('Features/BraydenM/FeatureBraydenMRoute')),
  // },
  FEATURE_LOUISE_JOHANSSON: {
    title: 'Louise Johansson',
    description: 'Foggy scenes are the speciality Swedish photographer Louise Johansson who combines both city night scenes with barren landscapes. Her colour grading is aggressive; with hues varying from purples, turquoises to "jello" yellows.',
    shortDescription: 'Scenes from a Nordic foggy town',
    category: 'Feature',
    date: '2022-01-06T14:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureLouiseJohansson.jpg`,
    blurhash: 'L*INs#WBM|ay~UWCWBfQofays:j[',
    path: '/features/louise-johansson/',
    hidden: false,
    component: React.lazy(() => import('Features/LouiseJohansson/FeatureLouiseJohanssonRoute')),
  },
  FEATURE_KIMMO_OJANIEMI: {
    title: 'Kimmo Ojaniemi',
    description: 'Kimmo Ojaniemi is a visual artist who has a photographic style that lies between the realms of the real and the surreal. His work conists mainly of murky greens and browns that feature foggy landscapes, interspersed with the odd city related capture.',
    shortDescription: 'Desolate and murky landscapes',
    category: 'Feature',
    date: '2021-11-19T14:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureKimmoOjaniemi.jpg`,
    blurhash: 'L03ucw0JMv=}Lgz-ivXS0w}ZxwAZ',
    path: '/features/kimmo-ojaniemi/',
    hidden: false,
    component: React.lazy(() => import('Features/KimmoOjaniemi/FeatureKimmoOjaniemiRoute')),
  },
  FEATURE_ELENA_SOKOLOVA: {
    title: 'Elena Sokolova',
    description: 'UX designer, Elena Sokolova, has a style that is dark and wistful, that mirrors the climate of her town St Petersburg. Her work features hazy desaturated imagery of landscapes and occasionally portraits, colour graded with metallic blacks and blues and the odd speck of colour.',
    shortDescription: 'Dark photography from a dark northern climate',
    category: 'Feature',
    date: '2021-10-06T13:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureElenaSokolova.jpg`,
    blurhash: 'L52r{+j]RMo$yGkDRhohx{ogV?j]',
    path: '/features/elena-sokolova/',
    hidden: false,
    component: React.lazy(() => import('Features/ElenaSokolova/FeatureElenaSokolovaRoute')),
  },
  FEATURE_ERWAN_LE_GUILLERMIC: {
    title: 'Erwan Le Guillermic',
    description: 'Erwan Le Guillermic is a Brittany-based photographer with a portfolio largely showcasing colourful sunsets, cloudscapes, and other subjects borrowed from nature. His style is largely minimalist, with a juxtaposition of warm sunset colours combined with the cooler palette of night photography and moody cloud patterns.',
    shortDescription: 'Warm sunsets and cool moonrises',
    category: 'Feature',
    date: '2021-09-15T13:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureErwanLeGuillermic.jpg`,
    blurhash: 'L15hGcD$0yo~.9%NMwg30~tS}XVr',
    path: '/features/erwan-le-guillermic/',
    hidden: false,
    component: React.lazy(() => import('Features/ErwanLeGuillermic/FeatureErwanLeGuillermicRoute')),
  },
  FEATURE_ANDERS_BEULICH: {
    title: 'Anders Beulich',
    description: 'Shooting exclusively with 40+ year old cameras, Anders Beulich\'s photography almost inevitably harks back to a style of a bygone era. His usage of film that was designed to be used during the day, results in a golden-green colour cast, which when combined with red light sources, lends a warm ambience to the images.',
    shortDescription: 'Gritty and nostalgia-laced street photography',
    category: 'Feature',
    date: '2021-08-18T13:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureAndersBeulich.jpg`,
    blurhash: 'L7A+m4D-3SIr{$-l11OW0P$~rZNv',
    path: '/features/anders-beulich/',
    hidden: false,
    component: React.lazy(() => import('Features/AndersBeulich/FeatureAndersBeulichRoute')),
  },
  FEATURE_LASSE_ERKOLA: {
    title: 'Lasse Erkola',
    description: 'Lasse Erkola is a Finnish-based photographer with a heart based in Japan. His photos aim to capture lonely subjects; with people going about their daily lives set against a minimalist backdrop, or desolate buildings illuminated by fluorescent or neon light sources.',
    shortDescription: 'Lonely subjects, dirty alleyways and fluorescent lighting; all set in Japan',
    category: 'Feature',
    date: '2021-07-22T13:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureLasseErkola.jpg`,
    blurhash: 'L37-Wf03-O}@9~tP9bOU00^~Rp9u',
    path: '/features/lasse-erkola/',
    hidden: false,
    component: React.lazy(() => import('Features/LasseErkola/FeatureLasseErkolaRoute')),
  },
  FEATURE_JOSIAH_STEWART: {
    title: 'Josiah Stewart',
    description: 'Medical scientist (and part-time nightwalker) Josiah Stewart, is our next guest with a portfolio showcasing American suburbia at night. His photos have a nostalgic, film-like feel to them; choosing subjects that have a retro vibe, and then later edited with colour-grading borrowed from the Cinestill tungsten stock.',
    shortDescription: 'Shop fronts, filmic vibes, and a whole lot of Cinebloom',
    category: 'Feature',
    date: '2021-07-12T13:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureJosiahStewart.jpg`,
    blurhash: 'LC6TR6UHUbQ8dWnOTJSiQ8q]YQY6',
    path: '/features/josiah-stewart/',
    hidden: false,
    component: React.lazy(() => import('Features/JosiahStewart/FeatureJosiahStewartRoute')),
  },
  FEATURE_AAKAASH_BALI: {
    title: 'Aakaash Bali',
    description: '"Emotionally driven photographs" is how our next photographer Aakaash Bali describes his work; and with his sombre, yet colourful, story-led approach to photography, you can see why. Aakaash makes use of cinematic setups, dramatic lighting, with tasteful editing and a whole lot of planning to create his work - all brought together in stunning high detail medium-format glory.',
    shortDescription: 'Stories best told when the lights are out',
    category: 'Feature',
    date: '2021-06-29T13:10:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureAakaashBali.jpg`,
    blurhash: 'L15XcH{e2t1Ii#wKOqEy5-JA$N=G',
    path: '/features/aakaash-bali/',
    hidden: false,
    component: React.lazy(() => import('Features/AakaashBali/FeatureAakaashBaliRoute')),
  },
  FEATURE_HAKIM_KABBAJ: {
    title: 'Hakim Kabbaj',
    description: 'Covering subjects such as landscapes, city scenes, and abstract portraiture, Hakim Kabbaj has a dark and emotion-evoking style that broadly isolates subjects against a minimalist backdrop. His work often combines bold contrast, rich colours and lifted shadows pushed towards the blue spectrum, which give the photos a theme that is both distinct and futuristic in its nature.',
    shortDescription: 'Dark, minimalistic photography from another dimension',
    category: 'Feature',
    date: '2021-06-21T14:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureHakimKabbaj.jpg`,
    blurhash: 'LE4OMnptQVWUd7pGjFbYQ:g2pGjs',
    path: '/features/hakim-kabbaj/',
    hidden: false,
    component: React.lazy(() => import('Features/HakimKabbaj/FeatureHakimKabbajRoute')),
  },
  FEATURE_CAGLA_POLAT: {
    title: 'Çağla Polat',
    description: 'Isanbul-based student Çağla Polat, has a distinct approach to her photography which combines sombre city scenes with cyberpunk-inspired colours and double exposures. Keep reading to find out more about what influences her work and how posting travel photos to Instagram kickstarted her photography journey.',
    shortDescription: 'Forgotten places, foggy scenes, and unrecognisable faces',
    category: 'Feature',
    date: '2021-06-10T14:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureCaglaPolat.jpg`,
    blurhash: 'L78{,e}79~0}xYkDEMNH0gOZ$exG',
    path: '/features/cagla-polat/',
    hidden: false,
    component: React.lazy(() => import('Features/CaglaPolat/FeatureCaglaPolatRoute')),
  },
  FEATURE_PETER_KRUGER: {
    title: 'Peter Kruger',
    description: 'Going by the IG name "Empty City", one can safely guess what Seattle-based photographer Peter Kruger shoots. His style aims to capture film-like scenes devoid of people, often with a focus on industrial locations. With raised shadows and subtle colour grading, the photos have a look that is both stylised yet sympathetic to the subject in question.',
    shortDescription: 'Exploring an empty city for film locations',
    category: 'Feature',
    date: '2021-06-03T14:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featurePeterKruger.jpg`,
    blurhash: 'LH9HX[R.9F-;_NM|D%-p%LIUIU%2',
    path: '/features/peter-kruger/',
    hidden: false,
    component: React.lazy(() => import('Features/PeterKruger/FeaturePeterKrugerRoute')),
  },
  FEATURE_FILIPPO_GIANI: {
    title: 'Filippo Giani',
    description: 'Filippo Giani is a filmmaker by day and forest-walker by night. His style could be described as ethereal, often utilising the fine outlines of bare trees against a shimmering light. Combined with underexposed dark ochre tones, it lends a sinister feel to the imagery that is captivating and haunting in equal measure.',
    shortDescription: 'Walking through ethereal forests after hours',
    category: 'Feature',
    date: '2021-05-27T14:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureFilippoGiani.jpg`,
    blurhash: 'L48{R4}W11-81KJ9$$Nc0QEh^3I?',
    path: '/features/filippo-giani/',
    hidden: false,
    component: React.lazy(() => import('Features/FilippoGiani/FeatureFilippoGianiRoute')),
  },
  FEATURE_PETER_DOBOZY: {
    title: 'Peter Dobozy',
    description: 'Philosophy graduate Peter Dobozy, has a dark and foreboding style that he applies to subjects from city streets to mountainous landscapes. His photography features fog, colorful light sources, hazy subjects and deep shadows - all shot on an iPhone.',
    shortDescription: 'Atmospheric suburban and landscape photography shot on an iPhone',
    category: 'Feature',
    date: '2021-05-20T14:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featurePeterDobozy.jpg`,
    blurhash: 'L15O~RNHNG.lQ8Vst7RP00%MV[8w',
    path: '/features/peter-dobozy/',
    hidden: false,
    component: React.lazy(() => import('Features/PeterDobozy/FeaturePeterDobozyRoute')),
  },
  FEATURE_MARK_BROYER: {
    title: 'Mark Broyer',
    description: 'Mark Broyer is photographer that specialises in capturing urban decay in his hometown of Hamburg. His work combines vivid lighting, weather-induced atmosphere and cinematic colour-grading to capture everyday streets in all their imperfect glory.',
    shortDescription: 'Scouring the streets of Hamburg for movie locations that will never be shot',
    category: 'Feature',
    date: '2021-05-12T14:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureMarkBroyer.jpg`,
    blurhash: 'L26Hcg^k554-}EMwOroy009u=z^6',
    path: '/features/mark-broyer/',
    hidden: false,
    component: React.lazy(() => import('Features/MarkBroyer/FeatureMarkBroyerRoute')),
  },
  FEATURE_JEN_HUSTON: {
    title: 'Jen Huston',
    description: 'By day, Jen Huston "brings babies into the world", but at night, she scours her local suburban streets for any sign of light. Read on to find out how a personal tragedy kickstarted her photographic journey and why she loves adverse weather conditions.',
    shortDescription: 'Suburban scenery shouded in fog',
    category: 'Feature',
    date: '2021-05-06T13:27:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureJenHuston.jpg`,
    blurhash: 'L79?,r}@5T0MK7S6$%$x01OW$z=w',
    path: '/features/jen-huston/',
    hidden: false,
    component: React.lazy(() => import('Features/JenHuston/FeatureJenHustonRoute')),
  },
  FEATURE_TYRON_SHI: {
    title: 'Tyron Shi',
    description: 'With classic cinematic teal overtones, desaturation of all colours but reds, and moody subjects, our next guest Tyson Shi is a US-based photographer who captures Manhattan with a modern take on film noir.',
    shortDescription: 'Capturing a real life dystopia in Manhattan',
    category: 'Feature',
    date: '2021-04-28T13:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureTyronShi.jpg`,
    blurhash: 'LD69XfHrM1*HyqMeaitjHsoyyWQ.',
    path: '/features/tyron-shi/',
    hidden: false,
    component: React.lazy(() => import('Features/TyronShi/FeatureTyronShiRoute')),
  },
  FEATURE_ROBERT_FRAGUADA: {
    title: 'Robert Fraguada',
    description: 'Robert Fraguada is an architect who moonlights as a night photographer. His style examines the streets of NYC, with a mixture of candid and architectural shots. He joins us to tell us his photography story and where the alias \'Foul Matter\' comes from.',
    shortDescription: 'Moody street and archicture photography from the heart of NYC',
    category: 'Feature',
    date: '2021-04-22T14:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureRobertFraguada.jpg`,
    blurhash: 'LFC@4?D%IVIA~pDjD%tQDjayWBx[',
    path: '/features/robert-fraguada/',
    hidden: false,
    component: React.lazy(() => import('Features/RobertFraguada/FeatureRobertFraguadaRoute')),
  },
  FEATURE_MATHYS_HADDOUCHE: {
    title: 'Mathys Haddouche',
    description: 'Management and engineering student Mathys Haddouche is a street photographer that makes the most of lonely subjects, artificial lighting and diffusion filters to create a dreamlike effect to his images. Read on to find out how photography has become a real outlet for him and how he hopes to one day make a living from the field.',
    shortDescription: 'Urban photography with a dreamy style',
    category: 'Feature',
    date: '2021-04-07T14:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureMathysHaddouche.jpg`,
    blurhash: 'L68NIM9b0N-obHWCoJs.02-o~9M|',
    path: '/features/mathys-haddouche/',
    hidden: false,
    component: React.lazy(() => import('Features/MathysHaddouche/FeatureMathysHaddoucheRoute')),
  },
  FEATURE_HANS_KVERNSJOLI: {
    title: 'Hans Kvernsjøli',
    description: 'Hans Kvernsjøli is a Norway-based photographer with a dark, foreboding style, and a heavy emphasis on deep blacks and desaturated colours. He joins us to talk about how he became acquainted with cameras and why he likes to shoot decaying structures.',
    shortDescription: 'Capturing the atmosphere of landscapes, old buildings and urban decay',
    category: 'Feature',
    date: '2021-03-31T14:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureHansKvernsjoli.jpg`,
    blurhash: 'LF8EPXazRPtR_NofRjozoItRayV@',
    path: '/features/hans-kvernsjoli/',
    hidden: false,
    component: React.lazy(() => import('Features/HansKvernsjoli/FeatureHansKvernsjoliRoute')),
  },
  FEATURE_JAMIE_ROBBINS: {
    title: 'Jamie Robbins',
    description: 'Our next guest is well-traveled film student Jamie Robbins, who in addition to partaking in Film Studies, is also an orchestral musician, and runs the IG account Visualgrams.',
    shortDescription: 'A film student who loves to shoot dark scenes with lonely subjects',
    category: 'Feature',
    date: '2021-03-24T20:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureJamieRobbins.jpg`,
    blurhash: 'L=Df72x]V@bb.lt7aef*Q.aefkja',
    path: '/features/jamie-robbins/',
    hidden: false,
    component: React.lazy(() => import('Features/JamieRobbins/FeatureJamieRobbinsRoute')),
  },
  FEATURE_ROSS_BUSWELL: {
    title: 'Ross Buswell',
    description: 'Dreamlike and ethereal landscapes are the speciality of Ross Buswell, who appropriately goes by the moniker Atmospherics. Read on to find out more about how Ross caught the photography bug and what his favourite conditions are to achieve his unique look.',
    shortDescription: 'Atmospheric by name, atmospheric by nature',
    category: 'Feature',
    date: '2021-03-17T20:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureRossBuswell.jpg`,
    blurhash: 'LBDI:=0J0J$+k?RixGWB00}]~CS4',
    path: '/features/ross-buswell/',
    hidden: false,
    component: React.lazy(() => import('Features/RossBuswell/FeatureRossBuswellRoute')),
  },
  FEATURE_AUDREY_MARQUIS: {
    title: 'Audrey Marquis',
    description: 'Despite starting out less than a year ago, Audrey Marquis has already amassed a sizable following due to her ethereal and surreal photography. We speak to her to find out what she looks for in a scene, and why houses and empty streets feature so prominently in her work.',
    shortDescription: 'Creating fantasy worlds from empty streets and old houses',
    category: 'Feature',
    date: '2021-03-10T20:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureAudreyMarquis.jpg`,
    blurhash: 'L87BJFtORljG_Goxo0jZItWEs.kA',
    path: '/features/audrey-marquis/',
    hidden: false,
    component: React.lazy(() => import('Features/AudreyMarquis/FeatureAudreyMarquisRoute')),
  },
  FEATURE_MOLLY_FLETCHER: {
    title: 'Molly Fletcher',
    description: 'There is no shortage of low light for Glasgow resident, Molly Fletcher, who tells us how the seed of photography was planted as a "Bebo scene kid", and why selfies have helped her ease the photography itch during lockdown.',
    shortDescription: 'Selfies, editing for authenticity and Scotland\'s varying shades of grey',
    category: 'Feature',
    date: '2021-03-03T20:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureMollyFletcher.jpg`,
    blurhash: 'LG6[dTt7RjbI?^t7Rjj]%gofWBj[',
    path: '/features/molly-fletcher/',
    hidden: false,
    component: React.lazy(() => import('Features/MollyFletcher/FeatureMollyFletcherRoute')),
  },
  FEATURE_THIMO_RUPPEL: {
    title: 'Thimo Ruppel',
    description: 'Mysterious, bleak and murky green landscapes sum up our next featured photographer, Thimo Ruppel, who takes us through how he creates his eerie moods.',
    shortDescription: 'Mysterious, bleak and murky landscapes from the depths of northern Germany',
    category: 'Feature',
    date: '2021-02-24T20:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureThimoRuppel.jpg`,
    blurhash: 'LA7.24x[ICRk_LofRkWCxtafWBay',
    path: '/features/thimo-ruppel/',
    hidden: false,
    component: React.lazy(() => import('Features/ThimoRuppel/FeatureThimoRuppelRoute')),
  },
  FEATURE_KARIN_KALLAS: {
    title: 'Karin Kallas',
    description: 'Colourful sunsets and sunrises are the speciality of Estonian photographer Karin Kallas, who has built up a loyal following based on her spectacular photos and willingness to share her method. Read on to find out about her "clay camera" and how she turned her back on the fashion industry for a life surrounded by nature.',
    shortDescription: 'Colourful sunsets and walking away from the fashion industry',
    category: 'Feature',
    date: '2021-02-17T20:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureKarinKallas.jpg`,
    blurhash: 'LdCRB3IUofRj?wRmjZWD%MRloLR+',
    path: '/features/karin-kallas/',
    hidden: false,
    component: React.lazy(() => import('Features/KarinKallas/FeatureKarinKallasRoute')),
  },
  FEATURE_CHELSEA_REECK: {
    title: 'Chelsea Reeck',
    description: 'We spoke to professor of photography Chelsea Reeck, who details her incredible series House Hunting and where the inspiration for it came from.',
    shortDescription: 'A professor of photography that hunts houses in the dead of night',
    category: 'Feature',
    date: '2021-02-11T20:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureChelseaReeck.jpg`,
    blurhash: 'LGBDpK00M{?^S#jGV@tRH?%goyIA',
    path: '/features/chelsea-reeck/',
    hidden: false,
    component: React.lazy(() => import('Features/ChelseaReeck/FeatureChelseaReeckRoute')),
  },
  FEATURE_GUSTAF_WIKING: {
    title: 'Gustaf Wiking',
    description: 'Our next featured interview is with Malmö resident, Gustaf Wiking, where we explore how his love of low light shooting stemmed from the long Nordic winter nights.',
    shortDescription: 'Visual documentation of the long Nordic nights ',
    category: 'Feature',
    date: '2021-02-05T20:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureGustafWiking.jpg`,
    blurhash: 'L2AvR605AI05Mf-nE3-n04^g$y^#',
    path: '/features/gustaf-wiking/',
    hidden: false,
    component: React.lazy(() => import('Features/GustafWiking/FeatureGustafWikingRoute')),
  },
  FEATURE_PAVENA_WOODHOUSE: {
    title: 'Pavena Woodhouse',
    description: 'Covering subjects from "cringey emo" to Korean revenge movies, Toronto native Pavena Woodhouse spends some time talking to us about what has influenced her work of shooting suburbia at night.',
    shortDescription: 'Turning Toronto suburbs into a dreamy, neon filled wonderland',
    category: 'Feature',
    date: '2021-02-01T15:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featurePavenaWoodhouse.jpg`,
    blurhash: 'LNDREF}r9]0~I:WXs:oL139^$i=w',
    path: '/features/pavena-woodhouse/',
    hidden: false,
    component: React.lazy(() => import('Features/PavenaWoodhouse/FeaturePavenaWoodhouseRoute')),
  },
  FEATURE_ELISA_MINUTIELLO: {
    title: 'Elisa Minutiello',
    description: 'Italian photographer, Elisa Minutiello talks to us about her aspirations as a photographer and her love of vintage relics.',
    shortDescription: 'Moody scenes, vintage relics and an abundance of nostalgia',
    category: 'Feature',
    date: '2021-01-26T15:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureElisaMinutiello.jpg`,
    blurhash: 'L51qMoiwh~bvY*jFm,kCafbbe.f6',
    path: '/features/elisa-minutiello/',
    hidden: false,
    component: React.lazy(() => import('Features/ElisaMinutiello/FeatureElisaMinutielloRoute')),
  },
  FEATURE_JEREMY_WIENS: {
    title: 'Jeremy Wiens',
    description: 'Low light photographer Jeremy Wiens joins us to delve into how he started with photography and whether Covid-19 has impacted his shooting.',
    shortDescription: 'A study of light and shadow',
    category: 'Feature',
    date: '2021-01-21T18:00:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureJeremyWiens.jpg`,
    blurhash: 'L47^#xrq01kCR-j@s-oe00kX~UWB',
    path: '/features/jeremy-wiens/',
    hidden: false,
    component: React.lazy(() => import('Features/JeremyWiens/FeatureJeremyWiensRoute')),
  },
  FEATURE_MARVIN_J_LEE: {
    title: 'Marvin J. Lee',
    description: 'Our feature series kicks off with one of our favourite photographers, Marvin J. Lee, who gives us an insight into his filmic approach to photography.',
    shortDescription: 'Cinematic photography at it\'s finest',
    category: 'Feature',
    date: '2021-01-14T21:03:00.000Z',
    image: `${process.env.PUBLIC_URL}/images/articleThumbnails/featureMarvinJLee.jpg`,
    blurhash: 'LGGQzW0$0h-nrFt6OqV[04=w}rIq',
    path: '/features/marvin-j-lee/',
    hidden: false,
    component: React.lazy(() => import('Features/MarvinJLee/FeatureMarvinJLeeRoute')),
  },
});

const getArticleRoutes = () => {
  return Object.fromEntries(Object.entries(ARTICLES).filter(([key, value]) => !value.hidden));
};

const getLiveFeatures = () => {
  const currentTimestamp = Date.now();
  return Object.values(ARTICLES)
    .filter(item => (item.category === 'Feature') && (!item.hidden))
    .filter(item => Date.parse(item.date) <= currentTimestamp)
    .sort((a, b) => a.date < b.date ? 1 : -1);
};

const getDraftFeatures = (): Article[] => {
  const currentTimestamp = Date.now();
  return Object.values(ARTICLES)
    .filter(item => (item.category === 'Feature') && (!item.hidden))
    .filter(item => Date.parse(item.date) > currentTimestamp)
    .sort((a, b) => a.date < b.date ? 1 : -1);
};

export {
  ARTICLES,
  getArticleRoutes,
  getLiveFeatures,
  getDraftFeatures,
};
