import React from 'react';
import { RouteProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Page, MetaTags, SiteFooter, PageSection, MoreReading } from '_components';
import { ROUTES } from 'routes';
import './Error404Route.scss';

const Error404Route: React.FC<RouteProps> = (props) => {

  return (
    <Page className="Error404Route">

      <MetaTags
        title="404 | Fading Hours"
        description="Low Light Photography Magazine."
      />

      <PageSection maxWidth="wide">
        <h1 className="Error404Route__title">Oh no! 404!</h1>
        <h4 className="Error404Route__subtitle"><Link className="u-anchor u-anchor--accentHover u-anchor--underline" to={ROUTES.HOME.path}>Go back to the homepage</Link> or check out some of the links below.</h4>
      </PageSection>

      <PageSection maxWidth="wide">
        <MoreReading count={6}/>
      </PageSection>

      <SiteFooter />

    </Page>
  );
};

export { Error404Route };
export default Error404Route;
