import React from 'react';
import { RouteProps } from 'react-router';
import Error404Route from 'Errors/Error404Route';
import { getArticleRoutes } from 'articles';

type RouteItemProps = RouteProps & {
  path: string;
};

// The asRoutes function is used as an alternative to using the index signature below. The function ensures that autocomplete
// does not get broken.
//
// type Routes = {
//   [key: string]: RouteItemProps;
// };
//
// Taken from: https://stackoverflow.com/questions/52146544/why-autocomplete-stop-working-in-an-object-with-type-in-typescript
// Also see: https://youtrack.jetbrains.com/issue/WEB-34642

function asRoutes<T extends { [key: string]: RouteItemProps }>(arg: T): T {
  return arg;
}

const articles = getArticleRoutes();

const ROUTES = asRoutes({
  HOME: {
    path: '/',
    component: React.lazy(() => import('Home/HomeRoute')),
    exact: true,
  },
  ABOUT: {
    path: '/about/',
    component: React.lazy(() => import('About/AboutRoute')),
  },
  CONTACT: {
    path: '/contact/',
    component: React.lazy(() => import('Contact/ContactRoute')),
  },
  // Note that articles needs to go above /features, /interview etc because their path is /features/xxx or /interviews/xxx
  ...articles,
  CATCH_ALL: {
    path: '*',
    component: Error404Route
  },
});

export { ROUTES };
