import React from 'react';
import clsx from 'clsx';
import ReactTimeAgo from 'react-timeago';
import './TimeAgo.scss';

type TimeAgoProps = {
  date: string;
  className?: string;
};

const TimeAgo: React.FC<TimeAgoProps> = (props) => {

  const classes = clsx('TimeAgo', props.className);

  return (
    <div className={classes}>
      <ReactTimeAgo date={props.date} />
    </div>
  );
};

export { TimeAgo };
