import React from 'react';
import clsx from 'clsx';
import './Page.scss';

type PageProps = {
  className?: string;
};

const Page: React.FC<PageProps> = (props) => {

  const classes = clsx('Page', 'a-routeFadeIn', props.className);

  return (
    <div className={classes}>
      {props.children}
    </div>
  );
};

export { Page };
