import React from 'react';
import { ExternalLink } from '_components';
import { ArrowAction } from './ArrowAction';
import './LinksList.scss';

type LinksListProps = {
  groups: {
    label: string;
    items: {
      name: string;
      overrideName?: string;
      url: string | null;
    }[];
  }[];
};

const LinksList: React.FC<LinksListProps> = (props) => {

  return (
    <div className="LinksList">
        {props.groups.map((group, idx) =>
          <div className="LinksList__group" key={idx}>
            <div className="LinksList__groupTitle">{group.label}</div>
            {group.items.map((item, idx) =>
              <div className="LinksList__item" key={idx}>
                {item.url &&
                  <ExternalLink className="LinksList__itemLink u-anchor" href={item.url}>
                    <ArrowAction label={item.overrideName || item.name} />
                  </ExternalLink>
                }
                {!item.url &&
                  <span className="LinksList__itemName">{item.overrideName || item.name}</span>
                }
              </div>
            )}
          </div>
        )}
    </div>
  );
};

export { LinksList };
