import React from 'react';
import clsx from 'clsx';
import { useAnalytics } from '_hooks';

type ExternalLinkProps = {
  href: string;
  className?: string;
  [key: string]: any;
  onClick?: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) | undefined;
};

const ExternalLink: React.FC<ExternalLinkProps> = ({ href, className, children, onClick, ...restProps}) => {

  const { trackOutboundLink } = useAnalytics();

  const handleLinkClick = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    trackOutboundLink(evt.currentTarget.href);
    if (onClick) {
      onClick(evt);
    }
  };

  const classes = clsx('ExternalLink', className);

  return (
    <a
      className={classes}
      href={href}
      onClick={handleLinkClick}
      target="_blank"
      rel="noopener noreferrer"
      {...restProps}
      >
        {children}
      </a>
  );
};

ExternalLink.defaultProps = {
  className: '',
}

export { ExternalLink };
