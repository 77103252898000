import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// https://stackoverflow.com/questions/56420392/react-hooks-scroll-to-element
// https://www.reddit.com/r/reactjs/comments/aj3qbz/react_router_how_to_make_a_new_page_scroll_to_the/

type ScrollToTopProps = {
  delay?: number;
};

const ScrollToTop: React.FC<ScrollToTopProps> = (props) => {

  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, props.delay || 0);
  }, [location.pathname, props.delay]);

  return (null);
};

export { ScrollToTop };
