import React from 'react';
import { ExternalLink } from '_components';
import { ReactComponent as ButtonBuyMeACoffee } from '_images/icons/bmc-button.svg';
import './BuyMeACoffee.scss';

type BuyMeACoffeeProps = {};

const BuyMeACoffee: React.FC<BuyMeACoffeeProps> = (props) => {

  return (
    <div className="BuyMeACoffee">
      <h3 className="BuyMeACoffee__title">Help support Fading Hours</h3>
      <p className="BuyMeACoffee__description">We are a small independent photography magazine, founded in 2021, with a passion for helping amazing low-light photographers increase their exposure. Please consider donating a small amount using the button below, to help with supporting the website and its associated running costs. Your support is greatly appreciated - thank you!</p>
      <div>
        <ExternalLink
          className="BuyMeACoffee__link u-anchor u-anchor--fadeHover"
          href="https://www.buymeacoffee.com/fadinghours">
          <ButtonBuyMeACoffee className="BuyMeACoffee__badge" />
        </ExternalLink>
      </div>

    </div >
  );
};

export { BuyMeACoffee };
