import React from 'react';
import { Helmet } from 'react-helmet-async';

type MetaTagsProps = {
  title: string;
  description: string;
  ogType?: string;
  // This must be a relative URL - the window.location.origin is prefixed to the image prop
  image?: string;
};

const MetaTags: React.FC<MetaTagsProps> = (props) => {

  // process.env.PUBLIC_URL does not read homepage property in package.json (see https://github.com/facebook/create-react-app/issues/2575)
  // That's why a REACT_APP_PUBLIC_URL value has been created in .env
  const publicUrl = process.env.REACT_APP_PUBLIC_URL!;
  const url = publicUrl + window.location.pathname;
  const imageUrl = publicUrl + props.image;

  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta property="og:type" content={props.ogType} />
      <meta property="og:site_name" content="Fading Hours" />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="800" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@fadinghoursmag" />
      <meta property="twitter:creator" content="@fadinghoursmag" />
      <meta property="twitter:title" content={props.title} />
      <meta property="twitter:description" content={props.description} />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:image" content={imageUrl} />
    </Helmet>
  );
}

MetaTags.defaultProps = {
  ogType: 'website',
  image: '/images/opengraphThumbnail.png',
};

export { MetaTags };
