import React from 'react';
import clsx from 'clsx';
import { Carousel } from 'react-responsive-carousel';
import { LazyImage } from '_components';
import './ImageCarousel.scss';
import { ReactComponent as IconChevronLeft } from '_images/icons/FAL-chevron-left.svg';
import { ReactComponent as IconChevronRight } from '_images/icons/FAL-chevron-right.svg';


type ImageCarouselProps = {
  className?: string;
  images: string[];
  paddingTop?: string;
  caption?: string | JSX.Element;
};

const ImageCarousel: React.FC<ImageCarouselProps> = (props) => {

  const renderArrowPrev = (clickHandler: () => void, hasPrev: boolean, label: string) => {
    return <button className="ImageCarousel__arrow ImageCarousel__arrowPrev" onClick={clickHandler}><IconChevronLeft /></button>;
  }

  const renderArrowNext = (clickHandler: () => void, hasPrev: boolean, label: string) => {
    return <button className="ImageCarousel__arrow ImageCarousel__arrowNext" onClick={clickHandler}><IconChevronRight /></button>;
  }

  const classes = clsx('ImageCarousel', props.className);

  return (
    <div className={classes}>
      <div className="ImageCarousel__carousel">

        <Carousel
          showStatus={false}
          swipeable={true}
          emulateTouch={true}
          transitionTime={350}
          infiniteLoop={true}
          useKeyboardArrows={true}
          showThumbs={false}
          renderArrowPrev={renderArrowPrev}
          renderArrowNext={renderArrowNext}>
          {props.images.map((item, idx) =>
            <div key={idx}>
              <LazyImage paddingTop={props.paddingTop} src={item} />
            </div>
          )}
        </Carousel>
      </div>
      {props.caption &&
        <small className="ArticleImages__caption">{props.caption}</small>
      }
    </div>
  );
};

ImageCarousel.defaultProps = {
  paddingTop: '100%',
};

export { ImageCarousel };
