import React from 'react';
import { ReactCompareSlider } from 'react-compare-slider';
import './ImageCompare.scss';
import { ReactComponent as IconArrowsH } from '_images/icons/FAL-arrows-h.svg';

type ImageCompareProps = {
  imageOne: JSX.Element;
  imageTwo: JSX.Element;
  caption?: string | JSX.Element;
};

const ImageCompare: React.FC<ImageCompareProps> = (props) => {

  const handle = <div className="ImageCompare__handle">
    <div className="ImageCompare__handleLine">
      <div className="ImageCompare__handleLineStroke"></div>
    </div>
    <button className="ImageCompare__handleButton">
      <IconArrowsH className="ImageCompare__handleButtonIcon" />
    </button>
    <div className="ImageCompare__handleLine">
      <div className="ImageCompare__handleLineStroke"></div>
    </div>
  </div>

  return (
    <div className="ImageCompare">
      <div className="ImageCompare__image">
        <ReactCompareSlider
          handle={handle}
          itemOne={props.imageOne}
          itemTwo={props.imageTwo}
        />
      </div>
      {props.caption &&
        <small className="ImageCompare__caption">{props.caption}</small>
      }
    </div>
  );
};

export { ImageCompare };
