import React, { useState } from 'react';
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player';
import { Blurhash } from 'react-blurhash';
import './VimeoPlayer.scss';

type VimeoPlayerProps = {
  id: string;
  paddingTop?: string;
  blurhash?: string;
};

const VimeoPlayer: React.FC<VimeoPlayerProps> = (props) => {

  const url: string = `https://vimeo.com/${props.id}`;

  const styles: React.CSSProperties = {};
  if (props.paddingTop) styles.paddingTop = props.paddingTop;

  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });

  const [ready, setReady] = useState(false);
  const handleOnReady = () => {
    setReady(true);
  };

  const blurhashClasses = clsx('VimeoPlayer__blurhash', {
    'VimeoPlayer__blurhash--hide': ready,
  });

  return (
    <div className="VimeoPlayer" ref={ref} style={styles}>
      {props.blurhash &&
        <Blurhash className={blurhashClasses} hash={props.blurhash} width="100%" height="100%" />
      }
      {inView &&
        <div className="VimeoPlayer__wrapper">
          <ReactPlayer
            url={url}
            width="100%"
            height="100%"
            onReady={handleOnReady}
            loop={true}
            config={{
              vimeo: {
                playerOptions: {
                  responsive: true,
                }
              }
            }} />
        </div>
      }
    </div>
  );
};

export { VimeoPlayer };
