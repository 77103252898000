import React from 'react';
import { LazyImage } from '_components/LazyImage';
import './ArticleImages.scss';

type ArticleImagesItem = {
  image: string;
  blurhash?: string;
  imagePaddingTop?: string;
  colSpan?: number;
};

type ArticleImagesProps = {
  items: ArticleImagesItem[] | ArticleImagesItem;
  // Width:Height ratio of each image in the array. Can be overridden on a per-image basis.
  imagePaddingTop?: string;
  // Columns to span for each image in the array. Can be overridden on a per-image basis.
  colSpan?: number;
  caption?: string | JSX.Element;
};

const ArticleImages: React.FC<ArticleImagesProps> = (props) => {

  const imageStyle = (item: ArticleImagesItem): React.CSSProperties => ({
    gridColumn: `span ${item.colSpan || props.colSpan}`,
  });

  const images = Array.isArray(props.items) ? props.items : [props.items];

  return (
    <div className="ArticleImages">
      <div className="ArticleImages__grid">
        {images.map((item, idx) =>
          <div key={idx} className="ArticleImages__image" style={imageStyle(item)}>
            <LazyImage src={item.image} paddingTop={item.imagePaddingTop || props.imagePaddingTop} blurhash={item.blurhash} />
          </div>
        )}
      </div>
      {props.caption &&
        <small className="ArticleImages__caption">{props.caption}</small>
      }
    </div>
  );
};

ArticleImages.defaultProps = {
  colSpan: 12,
};

export { ArticleImages };
