import React from 'react';

type LoadingProps = {};

const Loading: React.FC<LoadingProps> = (props) => {

  return (
    <div className="Loading">
      {/* Loading... */}
    </div>
  );
};

export { Loading };
