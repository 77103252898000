import { atom } from 'recoil';

const analyticsEnabledState = atom({
  key: 'analyticsEnabledState',
  default: false,
});

const siteMenuVisibleState = atom({
  key: 'siteMenuVisibleState',
  default: false,
});

export {
  analyticsEnabledState,
  siteMenuVisibleState,
};
