import React from 'react';
import { Card } from '_components';
import { Article } from '_types';
import './CardList.scss';

type CardListProps = {
  articles: Article[];
};

const CardList: React.FC<CardListProps> = (props) => {

  return (
    <div className="CardList">
      <div className="CardList__grid">
        {props.articles.map((item, idx) =>
          <div className="CardList__gridItem" key={idx}>
            <Card article={item} key={idx} />
          </div>
        )}
      </div>
    </div>
  );
};

export { CardList };
