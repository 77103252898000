import { useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';
import ReactGA from 'react-ga';
import { analyticsEnabledState } from '_state';

type useAnalyticsType = {
  analyticsEnabled: boolean;
  enableAnalytics: () => void;
  disableAnalytics: () => void;
  trackPage: (path: string) => void;
  trackOutboundLink: (url: string) => void;
  trackEvent: (category: string, action: string, label: string) => void;
};

const trackingId: string = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '';
const domain = window.location.hostname === 'localhost' ? undefined : window.location.hostname.replace(/^www./, '.');
const userAgent = navigator.userAgent;
const isProduction = process.env.NODE_ENV === 'production';

const useAnalytics = (): useAnalyticsType => {

  const [cookies, setCookie, removeCookie] = useCookies();
  const [analyticsEnabled, setAnalyticsEnabled] = useRecoilState(analyticsEnabledState);

  // All cookies can be stored for 1 year according to GDPR
  const oneYearDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return date;
  };

  const enableAnalytics = () => {
    if (userAgent === 'ReactSnap' || !isProduction) return;
    ReactGA.initialize(trackingId);
    setCookie('fh.an', '1', {
      expires: oneYearDate(),
      domain: domain,
      path: '/'
    });
    setAnalyticsEnabled(true);
  };

  const disableAnalytics = () => {
    removeCookie('_ga', { path: '/', domain: domain });
    removeCookie('_gat', { path: '/', domain: domain });
    removeCookie('_gid', { path: '/', domain: domain });
    setCookie('fh.an', '0', {
      expires: oneYearDate(),
      domain: domain,
      path: '/'
    });
    setAnalyticsEnabled(false);
  };

  const trackPage = useCallback((path: string) => {
    if (!analyticsEnabled) return;
    ReactGA.pageview(path);
  }, [analyticsEnabled]);

  const trackOutboundLink = (url: string) => {
    if (!analyticsEnabled) return;
    ReactGA.outboundLink({
      label: url
    }, () => { });
  };

  const trackEvent = (category: string, action: string, label: string) => {
    if (!analyticsEnabled) return;
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  // Set the default value based on the cookie (if the analytics cookie does not exist then we assume analytics to be ON)
  useEffect(() => {
    if (cookies['fh.an'] === '0') {
      disableAnalytics();
    }
    else {
      enableAnalytics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    analyticsEnabled,
    enableAnalytics,
    disableAnalytics,
    trackPage,
    trackOutboundLink,
    trackEvent,
  };
};

export { useAnalytics };
