import React from 'react';
import { LinksList } from './LinksList';
import './ProductLinks.scss';

type ProductLinksProps = {
  groups: {
    label: 'Camera Body' | 'Camera Lens' | 'Accessories';
    items: {
      name: string;
      overrideName?: string;
      url: string | null;
    }[];
  }[];
};

const ProductLinks: React.FC<ProductLinksProps> = (props) => {

  return (
    <div className="ProductLinks">
      <div className="ProductLinks__grid">
        <div className="ProductLinks__gridTitle">
          <h3 className="ProductLinks__title">Equipment</h3>
        </div>
        <div className="ProductLinks__gridProducts">
          <LinksList groups={props.groups} />
        </div>
      </div>
      <small className="ProductLinks__caption">Please note that some of these are Amazon affiliate links that help to support the running of this site.</small>
    </div>
  );
};

export { ProductLinks };
