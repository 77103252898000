import React from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ROUTES } from 'routes';
import { siteMenuVisibleState } from '_state';
import './SiteLogo.scss';
import { ReactComponent as Logo } from '_images/logos/logoFull.svg';

type SiteLogoProps = {};

const SiteLogo: React.FC<SiteLogoProps> = (props) => {

  const [, setSiteMenuVisible] = useRecoilState(siteMenuVisibleState);

  const handleClick = () => {
    setSiteMenuVisible(false);
  };

  return (
    <NavLink
      to={ROUTES.HOME.path}
      className="SiteLogo u-anchor u-anchor--fadeHover"
      exact={true}
      onClick={handleClick}>
      <Logo className="SiteLogo__logo" />
    </NavLink>
  );
};

export { SiteLogo };
