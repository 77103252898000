import React from 'react';
import clsx from 'clsx';
import './SiteFooter.scss';

type SiteFooterProps = {
  margin?: boolean;
};

const SiteFooter: React.FC<SiteFooterProps> = (props) => {

  const year = (new Date()).getFullYear();

  const classes = clsx('SiteFooter', {
    'SiteFooter--margin': props.margin,
  })

  return (
    <div className={classes}>
      <small>&copy; Copyright Fading Hours {year}. All images published on this site are copyrighted to their respective owners.</small>
    </div>
  );
};

SiteFooter.defaultProps = {
  margin: true,
};

export { SiteFooter };
