import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from '_hooks';

type AnalyticsTrackerProps = {
  enabled: boolean;
};

const AnalyticsTracker: React.FC<AnalyticsTrackerProps> = (props) => {

  const location = useLocation();
  const { trackPage } = useAnalytics();

  useEffect(() => {
    if (props.enabled) {
      trackPage(location.pathname);
    }
  }, [location.pathname, props.enabled, trackPage]);

  return (null);
};

export { AnalyticsTracker };
