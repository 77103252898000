import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { CSSTransition } from 'react-transition-group';
import { ROUTES } from 'routes';
import { siteMenuVisibleState } from '_state';
import './SiteMenu.scss';
import { ReactComponent as IconTwitter } from '_images/icons/FA-twitter.svg';
import { ReactComponent as IconInstagram } from '_images/icons/FA-instagram.svg';
import { ReactComponent as IconBuyMeACoffee } from '_images/icons/bmc-logo.svg';
import { ExternalLink } from './ExternalLink';

type SiteMenuProps = {};

const SiteMenu: React.FC<SiteMenuProps> = (props) => {

  const [siteMenuVisible, setSiteMenuVisible] = useRecoilState(siteMenuVisibleState);

  const closeMobileMenu = () => {
    setSiteMenuVisible(false);
  };

  // NOTE: The line below is required because CSSTransition in react-transition-group uses findDomNode which
  // throws an error in React Strict Mode. The nodeRef attribute on CSSTransition, and the ref attribute on
  // the div, can be safely removed once the library has been updated.
  const nodeRef = useRef(null);

  return (
    <CSSTransition in={siteMenuVisible} classNames="fade" nodeRef={nodeRef} timeout={{ enter: 500, exit: 300 }} unmountOnExit>
      <div className="SiteMenu" ref={nodeRef}>
        {/* <NavLink
        to={ROUTES.HOME.path}
        className="u-anchor SiteMenu__logo"
        exact={true}>
        <img className="SiteMenu__logoImage" src={imgLogo} alt="Fading Hours" />
     </NavLink> */}
        <div className="SiteMenu__nav">
          <nav className="SiteMenu__navItems" role="navigation">
            <NavLink
              to={ROUTES.HOME.path}
              className="SiteMenu__link u-anchor u-anchor--accentHover"
              activeClassName="SiteMenu__link--active"
              onClick={closeMobileMenu}>
              Home
            </NavLink>
            <NavLink
              to={ROUTES.ABOUT.path}
              className="SiteMenu__link u-anchor u-anchor--accentHover"
              activeClassName="SiteMenu__link--active"
              onClick={closeMobileMenu}>
              About
            </NavLink>
            <NavLink
              to={ROUTES.CONTACT.path}
              className="SiteMenu__link u-anchor u-anchor--accentHover"
              activeClassName="SiteMenu__link--active"
              onClick={closeMobileMenu}>
              Contact
            </NavLink>
          </nav>
          <div className="SiteMenu__media">
            <ExternalLink
              className="SiteMenu__mediaLink u-anchor u-anchor--fadeHover"
              href="https://www.buymeacoffee.com/fadinghours"
              onClick={closeMobileMenu}>
              <IconBuyMeACoffee className="SiteMenu__mediaIcon" />
            </ExternalLink>
            <ExternalLink
              className="SiteMenu__mediaLink u-anchor u-anchor--accentHover"
              href="http://www.instagram.com/fadinghours"
              onClick={closeMobileMenu}>
              <IconInstagram className="SiteMenu__mediaIcon" />
            </ExternalLink>
            <ExternalLink
              className="SiteMenu__mediaLink u-anchor u-anchor--accentHover"
              href="https://twitter.com/fadinghoursmag"
              onClick={closeMobileMenu}>
              <IconTwitter className="SiteMenu__mediaIcon" />
            </ExternalLink>
          </div>
        </div>
      </div>
    </CSSTransition >
  );
};

export { SiteMenu };
