import React, { Suspense, useRef } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Loading, ScrollToTop, AnalyticsTracker, SiteLogo, SiteMenu, SiteMenuHamburger, MediaIcons } from '_components';
import { useAnalytics } from '_hooks';
import { ROUTES } from 'routes';
import './App.scss';

const App: React.FC = () => {

  const location = useLocation();
  const { analyticsEnabled } = useAnalytics();

  // NOTE: This is required because CSSTransition in react-transition-group uses findDomNode which
  // throws an error in React Strict Mode. This reference can be safely removed once the library
  // has been updated.
  const nodeRef = useRef(null);

  return (
    <div className="App">
      <ScrollToTop delay={210} />
      <AnalyticsTracker enabled={analyticsEnabled} />
      {/* <SiteHeader visible={true} /> */}
      <SiteLogo />
      <MediaIcons />
      <SiteMenuHamburger />
      <SiteMenu />
      <TransitionGroup>
        <CSSTransition key={location.pathname} nodeRef={nodeRef} classNames="fade" timeout={{ enter: 1000, exit: 200 }}>
          <Suspense fallback={<Loading />}>
            <Switch location={location}>
              {Object.values(ROUTES).map((item, idx) =>
                // @ts-ignore
                <Route {...item} key={idx} />
              )}
            </Switch>
          </Suspense>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export { App };
