import React from 'react';
import { useLocation } from 'react-router-dom';
import { CardListTiles } from '_components';
import { getLiveFeatures } from 'articles';
import './MoreReading.scss';

type MoreReadingProps = {
  title?: string;
  count: number;
};

const MoreReading: React.FC<MoreReadingProps> = (props) => {

  const location = useLocation();

  // Filter the current article out, and select the first few live articles
  const features = getLiveFeatures()
    .filter(item => item.path !== location.pathname)
    .slice(0, props.count);

  return (
    <div className="MoreReading">
      {props.title &&
        <h2 className="MoreReading__title">More features</h2>
      }
      <CardListTiles articles={features} />
    </div>
  );
};

export { MoreReading };
