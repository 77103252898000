import React from 'react';
import 'normalize.css';
import '_styles/index.scss';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { HelmetProvider } from 'react-helmet-async';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

const app = (
  <React.StrictMode>
    <RecoilRoot>
      <HelmetProvider>
        <CookiesProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </CookiesProvider>
      </HelmetProvider>
    </RecoilRoot>
  </React.StrictMode>
);

const rootElm = document.getElementById('root')!;
if (rootElm.hasChildNodes()) {
  ReactDOM.hydrate(app, rootElm);
} else {
  ReactDOM.render(app, rootElm);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
