import React from 'react';
import clsx from 'clsx';
import './ScrollDownNotice.scss';
import { ReactComponent as ArrowDownBig } from '_images/icons/arrowDownBig.svg';

type ScrollDownNoticeProps = {
  visible: boolean;
  className?: string;
};

const ScrollDownNotice: React.FC<ScrollDownNoticeProps> = (props) => {

  const classes = clsx('ScrollDownNotice', props.className, {
    'ScrollDownNotice--visible': !props.visible,
  });

  return (
    <div className={classes}>
      <div className="ScrollDownNotice__arrow">
        <ArrowDownBig className="ScrollDownNotice__arrowIcon" />
      </div>
      <div className="ScrollDownNotice__text">Scroll Down</div>
    </div>
  );
};

export { ScrollDownNotice };
